import React from "react"

import Body from '../components/layout/default/main'

export default () => (
    <Body>
        <h1>Misc Docs</h1>
        <ul>
            <li>What it means to be an autodidact</li>
            <li>How to achieve something</li>
            <li>Hosting a website as a non-technical person</li>
        </ul>
    </Body>
)
